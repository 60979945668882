<script lang="ts">
	import Layout from '../lib/components/Layout.svelte';
	import { ID2Page, ID2Slug, rootPageID, website } from '../lib/content/configuration.gen';
	import Loader from '../lib/components/elements/Loader.svelte';

	const page = ID2Page[rootPageID];
	const description = page.metadata.description.length
		? page.metadata.description
		: website.metadata.description;
	const url = new URL(ID2Slug[page.ID], website.metadata.baseURL).toString();
</script>

<svelte:head>
	<title>{page.name} | {website.name}</title>
	<meta name="description" content={description} />
	<meta property="og:title" content={page.metadata.title} />
	<meta property="og:description" content={description} />
	<meta property="og:url" content={url} />
	<meta property="og:type" content="website" />
	<!-- <meta property="og:image" content="" /> -->
	<!-- <meta property="og:image:alt" content="" /> -->
	<!-- <meta property="og:image:type" content="" /> -->
	<!-- <meta property="og:image:width" content="" /> -->
	<!-- <meta property="og:image:height" content="" /> -->
	<meta name="twitter:title" content={page.metadata.title} />
	<meta name="twitter:description" content={description} />
	<meta name="twitter:card" content="summary_large_image" />
	<!-- <meta name="twitter:image" content="" /> -->
	<!-- <meta name="twitter:image:alt" content="" /> -->
</svelte:head>

<Layout ID={rootPageID}>
	{#each page.blocks as block}
		<Loader {block} />
	{/each}
</Layout>
